<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <AutoCheckCertificateOptions
          v-if="!franchisesOpen"
          :options-data="data"
          @next="toggleFranchises"
        />
        <AutoCheckCertificateFranchises
          v-else
          :franchise-data="data"
          @back="toggleFranchises"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AutoCheckCertificates',
  components: {
    AutoCheckCertificateOptions: () =>
      import('./components/AutoCheckCertificateOptions'),
    AutoCheckCertificateFranchises: () =>
      import('./components/AutoCheckCertificateFranchises')
  },
  data: () => ({
    franchisesOpen: false
  }),
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleFranchises() {
      this.franchisesOpen = !this.franchisesOpen
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="sass">
$cazana-blue: hsl(202, 100%, 37%)
.multiselect
  min-width: 16rem
  max-width: 100%
.multiselect__option
  &.multiselect__option--highlight
    background: $cazana-blue
</style>
